import { PrismicError } from "./errors/PrismicError.js";
const getRepositoryName = repositoryEndpoint => {
  try {
    const hostname = new URL(repositoryEndpoint).hostname;
    if (hostname.endsWith("prismic.io") ||
    // Production
    hostname.endsWith("wroom.io") ||
    // Staging
    hostname.endsWith("wroom.test")) {
      return hostname.split(".")[0];
    }
  } catch {}
  throw new PrismicError(`An invalid Prismic Document API endpoint was provided: ${repositoryEndpoint}`, void 0, void 0);
};
export { getRepositoryName };
