import { LinkField, LinkToMediaField } from '@prismicio/client';
import { Promotion } from 'interfaces';
import { Product, productCatalogMockedResponse } from 'product-shared';
import { MenuItemWithDropdown } from './menu.interface';

export const mainNavigationMock = {
  mainNavigation: {
    nav: [
      {
        label: 'Home',
        offer: {
          content: 'content',
          displayName: 'displayName',
          id: 'id',
          images: {
            medium: {
              alt: 'alt',
              copyright: 'copyright',
              dimensions: {
                with: 100,
                height: 100,
              },
              u: 'https://images.prismic.io/sunrise-yallo/9034a34e-fc82-4b7c-8daf-0bc0356d63e5_SIM_1600x840_1.jpg?auto=compress,format&rect=0,93,1600,653&w=980&h=400',
            },
          },
          slices: [
            {
              color: 'red',
              label: 'label',
              position: 'normal',
              sliceData: {
                slice_label: 'slice_label',
                slice_type: 'squircle',
              },
              text: 'text',
              type: 'squircle',
            },
          ],
        },
        secondLevelEntries: [
          {
            cta: 'Explore plans',
            image: { link_type: 'Media' },
            link: {
              origin: 'http://localhost:4200',
              protocol: 'http:',
              username: '',
              password: '',
              host: 'localhost:4200',
            },
            text: '<p>Explore the best local and international plans</p>',
            title: 'Mobile Plans',
          },
        ],
        title: 'Mobile',
        type: 'menu_item',
      },
    ],
    phone: {
      phoneNumber: '0800 00 48 48',
      phoneNumberLink: {
        link_type: 'Web',
        url: 'tel:0800004848',
      },
    },
  },
};

export const menuItemWithDropdownMock: MenuItemWithDropdown = {
  product: {
    product: new Product(productCatalogMockedResponse),
    promotion: new Promotion(),
  },
  offer: {
    content: 'content',
    id: 'id',
    slices: [
      {
        label: 'label',
        type: 'squircle',
        primary: '',
        items: [],
        isHeadSlice: false,
      },
    ],
    useCustomContent: false,
    title: 'rewrew',
    titleAlignment: 'center',
    contentAlignment: 'center',
    normalPrice: '10',
    promoPrice: '5',
    priceSize: 'medium',
    pricePrefix: '',
    textAfterPrice: 'dsad',
    checkMarks: [{ chek_mark_content: 'test' }],
    textAfterCheckMarks: 'dsad',
  },
  secondLevelEntries: [
    {
      cta: 'Explore plans',
      image: <LinkToMediaField>{
        link_type: 'Media',
      },
      link: <LinkField>{
        link_type: 'Document',
        url: '/path/to/offers',
      },
      text: '<p>Explore the best local and international plans</p>',
      title: 'Mobile Plans',
    },
  ],
  title: 'Advanced',
  type: 'Advanced',
  label: 'Advanced',
  primary: true,
  items: ['items'],
  isHeadSlice: false,
  link: undefined,
};
