import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input, ViewEncapsulation } from '@angular/core';
import { LinkField } from '@prismicio/client';
import { BrandService } from 'brand';
import { SvgComponent } from 'icon';
import { LinkComponent } from 'link';

export type Card = {
  id: string;
  icon: string;
  title: string[];
  description: string[];
  link: LinkField;
};

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-action-card',
  imports: [CommonModule, LinkComponent, SvgComponent],
  templateUrl: './action-card.component.html',
  styleUrls: ['./action-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ActionCardComponent {
  public brandService = inject(BrandService);
  @Input({ required: true }) link: LinkField;
  @Input() icon: string;
  @Input() title: string;
  @Input() description: string;
  @Input() additionalClass = '';
}
