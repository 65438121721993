import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { HttpClient } from '@angular/common/http';
import { APP_ID, inject, Injectable, isDevMode } from '@angular/core';
import { Params } from '@angular/router';
import { Attributes, FeatureDefinition, GrowthBook } from '@growthbook/growthbook';
import { BehaviorSubject, firstValueFrom, map } from 'rxjs';
import { BrandService } from 'brand';
import { ENVIRONMENT_URLS_CONFIG_TOKEN, randomString, StorageKeys, StorageService } from 'utils';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  private storageService = inject(StorageService);
  private brandService = inject(BrandService);
  private http = inject(HttpClient);
  private appId = inject(APP_ID);
  private growthBook: GrowthBook;
  private renderer: () => void = undefined;
  private queryParamNamespace = 'gb_';
  private clientKey: string;
  public ready = new BehaviorSubject<boolean>(false);
  private environmentUrlsConfig = inject(ENVIRONMENT_URLS_CONFIG_TOKEN);
  public gbOverrideQueryParams: { [key: string]: string } = {};

  public init(clientKey: string, addToDataLayer: (data: Record<string, unknown>) => void) {
    this.clientKey = clientKey;
    this.growthBook = new GrowthBook({
      enableDevMode: isDevMode(),
      trackingCallback: (experiment, result) => {
        addToDataLayer({
          event: 'experiment_viewed',
          experiment_id: experiment.key,
          variation_id: result.key,
        });
      },
    });

    this.setAttributes({
      id: randomString(10),
      brand: this.brandService.brand,
      app: this.appId,
      devEnv: this.environmentUrlsConfig.devEnv,
    });

    this.setRenderer();
  }

  public isOn(featureKey: string): boolean {
    const storedValue = this.storageService.get<{ [key: string]: boolean }>(StorageKeys.FeatureFlag);
    const result = storedValue?.[featureKey];

    if (typeof result === 'boolean') {
      return result;
    } else {
      return this.growthBook.isOn(featureKey);
    }
  }

  public getFeatureValue<T>(featureKey: string, fallback: T): T {
    const storedValue = this.storageService.get<{ [key: string]: T }>(StorageKeys.FeatureFlag);
    const result = storedValue?.[featureKey];

    if (result !== undefined) {
      return result as T;
    } else {
      return this.growthBook.getFeatureValue(featureKey, fallback) as T;
    }
  }

  private async fetchFeatures() {
    return firstValueFrom(
      this.http
        .get<{
          features: Record<string, FeatureDefinition<unknown>>;
        }>(`https://cdn.growthbook.io/api/features/${this.clientKey}`)
        .pipe(map(res => res.features))
    );
  }

  public async loadFeatures(queryParams: Params) {
    const features = await this.fetchFeatures();
    await this.growthBook.setPayload({ features });
    this.ready.next(true);
    if (!Object.keys(queryParams).length) {
      return;
    }

    const storedFeatureFlags: { [key: string]: boolean } = {};

    for (const key in queryParams) {
      if (key.startsWith(this.queryParamNamespace)) {
        this.gbOverrideQueryParams[key] = queryParams[key];
        const featureKey = key.replace(this.queryParamNamespace, '');
        const featureExists = this.growthBook.evalFeature(featureKey).value !== null;

        if (featureExists) {
          const booleanValue = coerceBooleanProperty(queryParams[key]);
          const isBoolean = booleanValue.toString() === queryParams[key];
          storedFeatureFlags[featureKey] = isBoolean ? booleanValue : queryParams[key];
        } else {
          console.log(`Feature ${featureKey} doesn't exist.`);
          break;
        }
      }
    }

    if (Object.keys(storedFeatureFlags).length > 0) {
      this.storageService.set(StorageKeys.FeatureFlag, storedFeatureFlags, true);
    }
  }

  private setAttributes(attributes: Attributes) {
    this.growthBook.setAttributes(attributes);
  }

  public setAttributeOverrides(attributesToOverride: Attributes) {
    this.growthBook.setAttributeOverrides({ ...this.growthBook.getAttributes(), ...attributesToOverride });
  }

  public rerenderWhenFeaturesChangeCb(renderer: () => void) {
    this.renderer = renderer;
  }

  private setRenderer() {
    this.growthBook.setRenderer(() => {
      if (this.renderer) {
        this.renderer();
      }
    });
  }
}
