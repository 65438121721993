import { inject, Injectable } from '@angular/core';
import { Content, filter, PrismicDocument, Slice } from '@prismicio/client';
import { LanguageService } from 'language';
import { CachedDataLoaderService } from 'utils';
import { ChannelService } from './channel.service';
import { CmsService } from './cms.service';
import { AccordionItem } from './components/slices/centered-accordion/accordian-item';
import { CenteredAccordionSlice } from './components/slices/centered-accordion/centered-accordion.slice';
import { prismicToHtmlString } from './helpers/prismic-to-html-string';
import { TableDataProducts, TableHomeInternet, TableMobile, TableTv } from './interfaces/product-box-table.class';
import { NewLandingPageDocumentDataBodyCenteredAccordionSlice } from './types.generated.interface';

export const convertProductBoxTable = (slice: Content.ProductBoxDocumentDataBodySlice) => {
  if (slice.slice_type === 'table_tv') {
    return new TableTv(slice);
  }

  if (slice.slice_type === 'table_home_internet') {
    return new TableHomeInternet(slice);
  }

  if (slice.slice_type === 'table_data_products') {
    return new TableDataProducts(slice);
  }

  if (slice.slice_type === 'table_mobile') {
    return new TableMobile(slice);
  }
};

@Injectable({
  providedIn: 'root',
})
export class PrismicLibService {
  private cms = inject(CmsService);
  private dataLoader = inject(CachedDataLoaderService);
  private languageService = inject(LanguageService);
  private channels = inject(ChannelService);

  public async getProducts(channel = 'ol'): Promise<Content.ProductBoxDocumentData[]> {
    return this.dataLoader.get(this.getStateKey(channel), () => this.loadProducts(channel));
  }

  public async getProduct(productCode: string): Promise<Content.ProductBoxDocumentData> {
    return this.dataLoader.get(this.getStateKey(productCode), () => this.loadProduct(productCode));
  }

  public async getProductBoxes() {
    return await this.cms.getByType('product_box');
  }

  private async loadProducts(channelCode?: string) {
    let channel;
    let productBoxesFromPrismic;
    if (!channelCode) {
      productBoxesFromPrismic = await this.cms.getByType('product_box');
    } else {
      channel = await this.channels.findChannel(channelCode);
      productBoxesFromPrismic = await this.cms.getByContentRelationship('product_box', 'channels.channel', channel.id);
    }

    return productBoxesFromPrismic
      .filter(item => item.data.product_name)
      .map(prod => prod.data as unknown as Content.ProductBoxDocumentData);
  }

  private async loadProduct(productCode: string) {
    if (!productCode) return null;
    const productBoxesFromPrismic = await this.cms.getByType('product_box', [
      filter.at('my.product_box.product_code', productCode),
    ]);

    return productBoxesFromPrismic[0].data as unknown as Content.ProductBoxDocumentData;
  }

  private getStateKey(channel?: string) {
    return 'products_' + channel + '_' + this.languageService.current;
  }

  getSlices(prismicData: PrismicDocument) {
    return prismicData.data.body
      .concat(prismicData.data.body1 ? prismicData.data.body1 : [])
      .map((sliceData: Slice) => {
        if (sliceData.slice_type === 'centered_accordion') {
          return this.convertCenteredAccordion(sliceData as NewLandingPageDocumentDataBodyCenteredAccordionSlice);
        }
        console.error('Slice type not configured', sliceData.slice_type);
      })
      .filter((d: Slice) => d);
  }

  /**
   * Converts the raw centered_accordion slice data into a CenteredAccordionSlice object
   * @param sliceData Raw slice data with the type centered_accordion
   */
  convertCenteredAccordion(sliceData: NewLandingPageDocumentDataBodyCenteredAccordionSlice) {
    const accordionItems: AccordionItem[] = [];
    const primary = sliceData.primary;

    const slice = sliceData;
    for (const item of slice.items) {
      accordionItems.push({
        title: prismicToHtmlString(item.title),
        content: prismicToHtmlString(item.text),
      });
    }
    return new CenteredAccordionSlice(sliceData, prismicToHtmlString(primary.title), accordionItems);
  }
}
