import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { filter } from '@prismicio/client';
import { debounceTime, from, map, of, switchMap } from 'rxjs';
import { FormFieldComponent } from 'form-field';
import { LanguageService } from 'language';
import { CmsService } from 'prismic';
import { TranslatePipe } from 'translate';
import { ResultsComponent } from './results/results.component';
import { SearchResult } from './results/search-result';

@Component({
  selector: 'app-search',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FormsModule, ReactiveFormsModule, FormFieldComponent, TranslatePipe, ResultsComponent, AsyncPipe],
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss',
})
export class SearchComponent {
  private cmsService = inject(CmsService);
  private lang = inject(LanguageService);
  form = new FormGroup({
    search: new FormControl(''),
  });
  searchResults$ = this.form.controls.search.valueChanges.pipe(
    debounceTime(250),
    switchMap(query => {
      if (!query) {
        return of([]);
      }
      return from(
        this.cmsService.client.get({
          filters: [filter.fulltext('document', query), filter.at('document.type', 'article')],
          lang: this.cmsService.languages[this.lang.current],
          fetch: ['article.title'],
        })
      ).pipe(
        map(results => {
          return results.results
            .map(result => {
              const question = result.data.title;
              const formattedQuestion = this.calcQuestionParts(query, question);
              const rating = this.getRating(formattedQuestion);

              return <SearchResult>{
                id: result.id,
                question,
                formattedQuestion,
                rating,
              };
            })
            .sort((a, b) => b.rating - a.rating);
        })
      );
    })
  );

  public onSearchResultSelect() {
    this.form.controls.search.reset();
  }

  /**
   * Replaces the matched parts of the question with bold tags
   */
  private calcQuestionParts(query: string, question: string) {
    const queryParts = query.toLowerCase().split(' ');
    queryParts.forEach(part => {
      question = question.replace(new RegExp(part, 'gi'), match => (match.length ? `<b>${match}</b>` : match));
    });
    return question;
  }

  /**
   * Calculates the rating of the search result
   * The rating is based on how many characters are matched and how close the first match is to the beginning of the string
   * In the future, we could add more complex logic to calculate the rating, or maybe even a library
   */
  private getRating(formattedQuestion: string) {
    // give points based on the total number of matched characters
    let points = (formattedQuestion.match(/<b>.*?<\/b>/gm) || [])
      .map(match => match.length - 7)
      .reduce((a, b) => a + b, 0);

    // adding extra points for how close the first match is to the beginning of the string
    const firstMatch = formattedQuestion.indexOf('<b>');
    if (firstMatch >= 0) {
      points += Math.max(0, 5 - firstMatch / 2);
    }

    return points;
  }
}
