import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CurvedBoxComponent } from 'curved-box';
import { SliceClass } from 'interfaces';
import { LazySliceComponent } from '../lazy-slice/lazy-slice.component';

@Component({
  selector: 'lib-slices',
  templateUrl: './slices.component.html',
  standalone: true,
  imports: [NgTemplateOutlet, LazySliceComponent, CurvedBoxComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlicesComponent {
  @Input() slices: SliceClass[];
}
