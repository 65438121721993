import { Injectable } from '@angular/core';
import { LazyLoadedComponentType } from './components/lazy-slice/lasy-slice-types';

@Injectable({
  providedIn: 'root',
})
export class LazySliceBaseService {
  public async loadSliceComponent(type: string): Promise<LazyLoadedComponentType> {
    if (type === 'centered_accordion') return this.loadCenteredAccordionComponent();
    throw 'Invalid slice type: ' + type;
  }

  public async loadCenteredAccordionComponent() {
    const { CenteredAccordionComponent } = await import(
      './components/slices/centered-accordion/centered-accordion.component'
    );
    return CenteredAccordionComponent;
  }
}
