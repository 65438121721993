import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { BrandService } from 'brand';
import { SvgComponent } from 'icon';
import { ToastService } from 'toast';
import { TranslatePipe, TranslateService } from 'translate';
import { RfeCallService } from './rfe-call.service';

@Component({
  selector: 'lib-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss'],
  imports: [TranslatePipe, NgClass, SvgComponent, ReactiveFormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsletterComponent implements OnInit, OnDestroy {
  private rfeCall = inject(RfeCallService);
  private toastService = inject(ToastService);
  private translateService = inject(TranslateService);
  brand = inject<BrandService>(BrandService);
  private _destroyed = new Subject<void>();
  private _message: string;
  newsletterForm: FormGroup;
  formSubmitAttempt: boolean;
  loading: boolean;

  ngOnInit(): void {
    this.newsletterForm = new FormGroup({
      email: new FormControl(null, [Validators.email, Validators.required]),
    });
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  async submit() {
    this.formSubmitAttempt = true;

    if (this.newsletterForm.invalid || this.loading) {
      return;
    }

    this.loading = true;
    const resp = await this.rfeCall.newsletterSubmission(this.newsletterForm.value.email, 'footer');
    this.loading = false;
    this.formSubmitAttempt = false;

    if (resp?.errorCode || resp?.status !== 1) {
      const translationKey = resp?.errorCode ? `error_newsletter_${resp?.errorCode}` : 'error.generic';
      this._message = this.translateService.getTranslation([translationKey]);
      this.toastService.add(this._message, false);
    } else {
      this.newsletterForm.reset();
      this._message = this.translateService.getTranslation(['confirmationSubsNewsletterNotification']);
      this.toastService.add(this._message);
    }
  }
}
