import { Content, LinkField } from '@prismicio/client';
import { ProductCatalogService } from '@yol-digital/ms-client';
import { Image } from 'interfaces';
import {
  convertProductBoxTable,
  prismicToHtmlString,
  prismicToImage,
  TableDataProducts,
  TableHomeInternet,
  TableMobile,
  TableTv,
} from 'prismic';
import { urlify } from 'utils';
import CatalogResponse = ProductCatalogService.CatalogResponse;

export class Product {
  constructor(rateplan: CatalogResponse, prismicData?: Content.ProductBoxDocumentData, channelId?: string) {
    this.id = rateplan.id;
    this.product_code = rateplan.code;
    this.source = rateplan.source;
    this.name = prismicData?.display_name ? prismicData?.display_name : rateplan.name.replace(/yallo|lebara/g, '');
    this.url = urlify(rateplan.displayName);
    this.monthly_cost = rateplan.basePrice;
    this.activation_fee = rateplan.activationFee;
    this.rateplan_id = rateplan.id;
    this.lockin_length = rateplan.availableLockings;
    this.defaultPromotion = rateplan.defaultPromotion;
    this.productSpecFamily = rateplan.productSpecFamily;
    this.product_type =
      prismicData?.product_type?.toLocaleLowerCase() ?? rateplan.productSpecFamily.toLocaleLowerCase();
    this.productSpecClass = rateplan.productSpecClass;
    this.isSlave = rateplan.isSlave;
    this;
    //Get values from Prismic
    this.hasPrismicData = !!prismicData && Object.keys(prismicData).length !== 0;
    if (prismicData) {
      this.short_description = prismicToHtmlString(prismicData.short_description);
      if (prismicData.product_icon?.url) {
        this.product_icon = prismicToImage(prismicData.product_icon);
      }
      this.productSpecSubCategory = prismicData.product_spec_sub_category;
      this.product_details_link_label = prismicData.product_details_link_label;
      this.product_details_link = prismicData.product_details_link as LinkField;
      this.cta_button_label = prismicData.cta_button_label;
      this.alternative_link = prismicData.alternative_link;
      this.special_product_type = prismicData.special_product_type;
      this.promotion_banner_text = prismicData.promotion_banner_text;
      this.checkout_basket_additional_info = prismicToHtmlString(prismicData.checkout_basket_additional_info);
      if (prismicData.body && prismicData.body[0]) {
        this.prismicTable = convertProductBoxTable(prismicData.body[0]);
      }

      //A product from prismic can have multiple channels, so only add lockinLength if there's a channelId
      if (channelId && this.hasPrismicData) {
        const channelItem = prismicData.channels.find((d: { channel: { id: string } }) => d.channel.id === channelId);
        if (channelItem) {
          if (channelItem['0_month_lockin']) this.lockin_length.push(0);
          if (channelItem['12_month_lockin']) this.lockin_length.push(12);
          if (channelItem['24_month_lockin']) this.lockin_length.push(24);
        }
      }
    }

    this.type = this.productSpecClass === 'PREPAID' ? 'prepaid' : 'postpaid';

    this.setAnalyticsCategories(rateplan.productSpecClass, rateplan.productSpecFamily);
  }

  id: string;
  hasPrismicData: boolean;
  source: CatalogResponse['source'];
  name: string;
  short_description: string;
  url: string;
  monthly_cost: Content.ProductBoxDocumentData['monthly_cost'] | CatalogResponse['basePrice'];
  activation_fee?: CatalogResponse['activationFee'];
  rateplan_id: Content.ProductBoxDocumentData['rateplan_id'] | CatalogResponse['id'];
  type: Lowercase<Content.ProductBoxDocumentData['type']> | string;
  //TODO: Remove mbb and fiber when the content team migrate to product box kitsune
  product_type: Lowercase<Content.ProductBoxDocumentData['product_type']> | CatalogResponse['productSpecFamily'];
  lockin_length: number[] = []; // Note, this can be different depending on the channel
  product_icon: Image;
  product_details_link: Content.ProductBoxDocumentData['product_details_link'];
  product_details_link_label: Content.ProductBoxDocumentData['product_details_link_label'];
  cta_button_label: Content.ProductBoxDocumentData['cta_button_label'];
  alternative_link: Content.ProductBoxDocumentData['alternative_link'];
  promotion_banner_text: Content.ProductBoxDocumentData['promotion_banner_text'];
  special_product_type: Content.ProductBoxDocumentData['special_product_type'];
  product_code: Content.ProductBoxDocumentData['product_code'] | CatalogResponse['code'];
  isSlave: CatalogResponse['isSlave'];
  productSpecFamily: CatalogResponse['productSpecFamily'];
  productSpecSubCategory: Content.ProductBoxDocumentData['product_spec_sub_category'];
  productSpecClass: CatalogResponse['productSpecClass'];
  defaultPromotion?: CatalogResponse['defaultPromotion'];
  prismicTable?: TableHomeInternet | TableDataProducts | TableMobile | TableTv;
  item_category?: 'Mobile abo' | 'TV' | 'Prepaid' | 'Fixline' | 'Activation fee';
  item_category2?: 'Mobile voice' | 'TV' | 'Prepaid' | 'Mobile data' | 'Internet' | 'Activation fee';
  item_category3?: 'Mobile voice' | 'TV' | 'Prepaid SIM' | 'Internet on the Go' | 'Internet at Home' | 'Activation fee';
  item_category4?: 'Activation fee' | 'not selfcare';
  item_category5?: 'Activation fee' | 'not selfcare';
  item_variant?:
    | 'postpaid mobile'
    | 'tv subscription'
    | 'prepaid mobile'
    | 'postpaid mbb'
    | 'postpaid cable'
    | 'postpaid fiber'
    | 'service fees';
  checkout_basket_additional_info: string;

  discountedPrice(discount?: number): number {
    const cost = +this.monthly_cost;
    if (!cost || isNaN(cost) || !discount) {
      return this.monthly_cost;
    }

    return Number((cost - discount).toFixed(2));
  }

  setAnalyticsCategories(productSpecClass: string, productSpecFamily: string) {
    switch (productSpecClass?.toUpperCase() + '/' + productSpecFamily?.toUpperCase()) {
      case 'POSTPAID/MOBILE':
        this.item_category = 'Mobile abo';
        this.item_category2 = 'Mobile voice';
        this.item_category3 = 'Mobile voice';
        this.item_category4 = 'not selfcare';
        this.item_category5 = 'not selfcare';
        this.item_variant = 'postpaid mobile';
        break;
      case 'TV/TV':
        this.item_category = 'TV';
        this.item_category2 = 'TV';
        this.item_category3 = 'TV';
        this.item_category4 = 'not selfcare';
        this.item_category5 = 'not selfcare';
        this.item_variant = 'tv subscription';
        break;
      case 'PREPAID/MOBILE':
        this.item_category = 'Prepaid';
        this.item_category2 = 'Prepaid';
        this.item_category3 = 'Prepaid SIM';
        this.item_category4 = 'not selfcare';
        this.item_category5 = 'not selfcare';
        this.item_variant = 'prepaid mobile';
        break;
      case 'POSTPAID/INTERNET':
        this.item_category = 'Mobile abo';
        this.item_category2 = 'Mobile data';
        this.item_category3 = 'Internet on the Go';
        this.item_category4 = 'not selfcare';
        this.item_category5 = 'not selfcare';
        this.item_variant = 'postpaid mbb';
        break;
      case 'MBB/INTERNET':
        this.item_category = 'Mobile abo';
        this.item_category2 = 'Mobile data';
        this.item_category3 = 'Internet at Home';
        this.item_category4 = 'not selfcare';
        this.item_category5 = 'not selfcare';
        this.item_variant = 'postpaid mbb';
        break;
      case 'HFC/INTERNET':
        this.item_category = 'Fixline';
        this.item_category2 = 'Internet';
        this.item_category3 = 'Internet at Home';
        this.item_category4 = 'not selfcare';
        this.item_category5 = 'not selfcare';
        this.item_variant = 'postpaid cable';
        break;
      case 'FIBER/INTERNET':
        this.item_category = 'Fixline';
        this.item_category2 = 'Internet';
        this.item_category3 = 'Internet at Home';
        this.item_category4 = 'not selfcare';
        this.item_category5 = 'not selfcare';
        this.item_variant = 'postpaid fiber';
        break;
    }
  }
}
