import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { PrismicDocument } from '@prismicio/client';
import { DynamicHooksComponent } from 'ngx-dynamic-hooks';
import { BreadcrumbsComponent } from 'breadcrumbs';
import { SliceClass } from 'interfaces';
import { prismicToHtmlString, SlicesComponent } from 'prismic';
import { CategoryService } from '../category.service';
import { SupportArticle } from '../support-types';

@Component({
  selector: 'app-article-page',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DynamicHooksComponent, BreadcrumbsComponent, SlicesComponent],
  templateUrl: './article-page.component.html',
  styleUrl: './article-page.component.scss',
})
export class ArticlePageComponent {
  private categoryService = inject(CategoryService);
  public articleData = input<{ article: PrismicDocument<SupportArticle>; slices: SliceClass[] }>();
  public article = computed(() => this.articleData()?.article);
  public slices = computed(() => this.articleData()?.slices);
  public articleContent = computed(() => prismicToHtmlString(this.article()?.data.content));
  public categoryChain = toSignal(this.categoryService.getCategoryChain(this.article()?.data.category?.id));
}
