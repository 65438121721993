import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrismicDocument } from '@prismicio/client';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { map } from 'rxjs';
import { ActionCardComponent } from 'action-card';
import { SupportCategory } from '../support-types';
import { sortByDisplayOrder } from '../support-utils';
import { transformSupportDocLink } from '../transform-support-doc-link';

@Component({
  selector: 'app-home-page',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, ActionCardComponent, NgxSkeletonLoaderModule],
  templateUrl: './home-page.component.html',
  styleUrl: './home-page.component.scss',
})
export class HomePageComponent {
  protected readonly transformCardLink = transformSupportDocLink;
  private route = inject(ActivatedRoute);
  public categories$ = this.route.data.pipe(
    map(({ categories }) => sortByDisplayOrder(categories as PrismicDocument<SupportCategory>[]))
  );
}
