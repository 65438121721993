import { computed, inject, Signal } from '@angular/core';
import { Promotion } from 'interfaces';
import { Product } from 'product-shared';
import { TranslateService } from 'translate';

export class ProductBoxBase {
  protected translateService = inject(TranslateService);
  product: Signal<Product>;
  promotion: Signal<Promotion>;
  hasDiscountedCost = computed(
    () => this.product()?.discountedPrice(this.promotion()?.discountAmount) < this.product()?.monthly_cost
  );
  promotionBannerText = computed(() => {
    return (
      this.product()?.promotion_banner_text ||
      this.promotion().title ||
      `${Promotion.discountPercentage(
        Number(this.product()?.monthly_cost),
        this.promotion().discountAmount
      )}% ${this.translateService.getTranslation(['discount'])}`
    );
  });
  discountedPrice = computed(() => this.product()?.discountedPrice(this.promotion()?.discountAmount));
}
