import { DOCUMENT, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  inject,
  Input,
  model,
  OnInit,
  Output,
  signal,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, QueryParamsHandling, RouterLink } from '@angular/router';
import { FilledContentRelationshipField, FilledLinkToWebField, LinkField, LinkType } from '@prismicio/client';
import { SvgComponent } from 'icon';
import { Size, Style, Type } from 'interfaces';
import { LanguageService } from 'language';
import { ModalsService } from 'modal';
import { checkForAnchorTagLink, isNewLandingPage } from 'prismic';
import { App, ENVIRONMENT_URLS_CONFIG_TOKEN, EnvironmentUrlsConfig } from 'utils';

@Component({
  selector: 'lib-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SvgComponent, NgTemplateOutlet, RouterLink],
})
export class LinkComponent implements OnInit {
  private document = inject<Document>(DOCUMENT);
  private config = inject<EnvironmentUrlsConfig>(ENVIRONMENT_URLS_CONFIG_TOKEN);
  private modalsService = inject(ModalsService);
  private route = inject(ActivatedRoute);
  private languageService = inject(LanguageService);
  readonly LinkType = LinkType;
  isAnchorTagLink = signal(false);
  link = model<LinkField | FilledContentRelationshipField>();
  @Input() title = '';
  @Input() ariaLabel = '';
  @Input() queryParamsHandling: QueryParamsHandling | null;
  @Input() queryParams: object;
  @Input() icon: string;
  @Input() iconPosition: 'prefix' | 'suffix' = 'prefix';
  @Input() linkType: Type = 'default';
  @Input() linkStyle: Style | Style[] = 'default';
  @Input() linkSize: Size = 'default';

  @Input()
  get cssClass() {
    return `link ${this._cssClass} ${this.typeClass} ${this.styleClass} ${this.sizeClass}`;
  }

  set cssClass(value: string) {
    this._cssClass = value;
  }

  private _cssClass = '';
  @Input() textCssClass = '';
  @Output() onClick = new EventEmitter<never>();

  get typeClass() {
    switch (this.linkType) {
      case 'primary':
        return 'text-primary';
      case 'featured':
        return 'text-featured';
      case 'secondary':
        return 'text-gray-2';
      default:
        return '';
    }
  }

  get styleClass(): string {
    if (Array.isArray(this.linkStyle)) {
      return this.linkStyle
        .map(style => {
          return this._getStyleClass(style);
        })
        .join(' ');
    }

    return this._getStyleClass(this.linkStyle);
  }

  private _getStyleClass(style: Style) {
    switch (style) {
      case 'bold':
        return 'font-bold';
      case 'underline':
        return 'underline';
      default:
        return '';
    }
  }

  get sizeClass() {
    switch (this.linkSize) {
      case 'small':
        return 'text-sm';
      case 'large':
        return 'text-lg';
      case 'extra large':
        return 'text-xl';
      default:
        return '';
    }
  }

  get target() {
    switch (this.link().link_type) {
      case LinkType.Web:
        return (this.link() as FilledLinkToWebField).target ?? '_self';
      case LinkType.Media:
        return '_blank';
      default:
        return null;
    }
  }

  get documentType() {
    return (this.link() as FilledContentRelationshipField).type;
  }

  get isValidLink() {
    return this.link() && this.link().link_type !== LinkType.Any;
  }

  get hasPrefixIcon() {
    return this.icon && this.iconPosition === 'prefix';
  }

  get hasSuffixIcon() {
    return this.icon && this.iconPosition === 'suffix';
  }

  url = computed(() => {
    switch (this.link().link_type) {
      case LinkType.Media:
      case LinkType.Web:
        return (this.link() as FilledLinkToWebField).url;
      case LinkType.Document:
        return [
          '/',
          this.languageService.currentLanguage() ?? this.languageService.current,
          ...(this.link() as FilledContentRelationshipField).slug.split('/'),
        ];
      default:
        return null;
    }
  });

  ngOnInit() {
    if (checkForAnchorTagLink(this.link())) {
      this.isAnchorTagLink.set(true);
    }

    // If app is not website and the link is a new landing page, use web link instead of router
    this.route.data.subscribe(data => {
      if (isNewLandingPage(this.link()) && data.app && data.app !== App.website) {
        this.link.set({
          url: `${this.config.websiteUrl}/${this.languageService.currentLanguage() ?? this.languageService.current}/${
            (this.link() as FilledContentRelationshipField).slug
          }`,
          link_type: LinkType.Web,
        });
      }
    });
  }

  scrollTo(event: { preventDefault: () => void }, id: string | string[]): void {
    event.preventDefault();
    if (typeof id === 'string') {
      let anchorLink = '';
      if (id.startsWith('#')) {
        anchorLink = id.replace('#', '');
      }
      if (id.startsWith('https://#')) {
        anchorLink = id.replace('https://#', '');
      }
      (this.document.getElementById(anchorLink) as HTMLElement).scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }

  onMediaOrWebLinkClick() {
    this.onClick.emit();
  }

  onDocumentLinkClick() {
    if (this.documentType === 'modal_kitsune') {
      this.modalsService.openModal((this.link() as FilledContentRelationshipField).id);
    }
    this.onClick.emit();
  }
}
