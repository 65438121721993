export function appendScript(
  document: Document | undefined,
  src: string,
  defer = true,
  async = true,
  location: 'head' | 'body' = 'body',
  id?: string,
  attributes?: { [key: string]: string }
) {
  if (!document) {
    return;
  }
  const script = document.createElement('script');
  script.src = src;
  script.defer = defer;
  script.async = async;

  if (id) {
    script.id = id;
  }

  if (attributes) {
    Object.entries(attributes).forEach(([key, value]) => {
      script.setAttribute(key, value);
    });
  }

  document[location].appendChild(script);
}
