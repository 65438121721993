import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  APP_ID,
  ApplicationConfig,
  ErrorHandler,
  inject,
  provideAppInitializer,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideClientHydration, withEventReplay } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter, withComponentInputBinding, withEnabledBlockingInitialNavigation } from '@angular/router';
import { HookParserEntry, provideDynamicHooks } from 'ngx-dynamic-hooks';
import { AnalyticsService } from 'analytics';
import { AuthInterceptor } from 'auth-data-access';
import { BRAND_CONFIG, BrandConfig } from 'brand';
import { EshopProductService } from 'eshop-product';
import { FeatureFlagService } from 'feature-flag';
import { LinkComponent } from 'link';
import { CustomErrorHandler, httpErrorInterceptor } from 'monitoring';
import { CmsService, PRISMIC_ENDPOINT } from 'prismic';
import { ProductService } from 'product-shared';
import { SPRINKLR_APP_ID } from 'sprinklr-webwidget';
import { TooltipComponent } from 'tooltip';
import {
  TranslateService,
  TRANSLATION_DYNAMIC_VALUES,
  TRANSLATION_PRISMIC_TAG,
  TranslationDynamicValues,
} from 'translate';
import {
  App,
  BrowserService,
  calcEnvironmentUrls,
  ENVIRONMENT_URLS_CONFIG_TOKEN,
  EnvironmentUrlsConfig,
  FALLBACK_LANGUAGE,
} from 'utils';
import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';

const initFeatureFlags = (featureFlagService: FeatureFlagService, analyticsService: AnalyticsService) => () =>
  featureFlagService.init(environment.featureFlagClientKey, data => analyticsService.addToDataLayer(data));

const calcUrlsConfig = (browser: BrowserService): EnvironmentUrlsConfig =>
  calcEnvironmentUrls(environment, browser.getOrigin());

const componentParsers: Array<HookParserEntry> = [
  { component: TooltipComponent },
  {
    component: LinkComponent,
    inputsWhitelist: ['link'],
  },
];

const translationDynamicValues: TranslationDynamicValues = {
  BRAND: environment.brand === 'lebara' ? 'Lebara' : environment.brand,
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes, withEnabledBlockingInitialNavigation(), withComponentInputBinding()),
    provideClientHydration(withEventReplay()),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideHttpClient(withInterceptorsFromDi(), withFetch(), withInterceptors([httpErrorInterceptor])),
    provideAnimationsAsync(),
    provideDynamicHooks(componentParsers),
    provideAppInitializer(() => {
      const initializerFn = ((cmsService: CmsService, translateService: TranslateService) => async () => {
        await cmsService.init();
        return translateService.initialize();
      })(inject(CmsService), inject(TranslateService));
      return initializerFn();
    }),
    { provide: APP_ID, useValue: App.support },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: TRANSLATION_DYNAMIC_VALUES, useValue: translationDynamicValues },
    { provide: TRANSLATION_PRISMIC_TAG, useValue: App.support },
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    { provide: ENVIRONMENT_URLS_CONFIG_TOKEN, useFactory: calcUrlsConfig, deps: [BrowserService] },
    { provide: BRAND_CONFIG, useValue: <BrandConfig>{ brand: environment.brand } },
    { provide: FALLBACK_LANGUAGE, useValue: environment.fallbackLang },
    { provide: PRISMIC_ENDPOINT, useValue: environment.prismicEndpoint },
    { provide: ProductService, useClass: EshopProductService },
    { provide: SPRINKLR_APP_ID, useValue: environment.sprinklerAppId },
    provideAppInitializer(() => {
      const initializerFn = initFeatureFlags(inject(FeatureFlagService), inject(AnalyticsService));
      return initializerFn();
    }),
  ],
};
