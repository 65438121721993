const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
const pLimit = ({
  interval
} = {}) => {
  const queue = [];
  let busy = false;
  let lastCompletion = 0;
  const resumeNext = () => {
    var _a;
    if (!busy && queue.length > 0) {
      (_a = queue.shift()) == null ? void 0 : _a();
      busy = true;
    }
  };
  const next = () => {
    busy = false;
    resumeNext();
  };
  const run = async (function_, resolve, arguments_) => {
    const timeSinceLastCompletion = Date.now() - lastCompletion;
    if (interval && timeSinceLastCompletion < interval) {
      await sleep(interval - timeSinceLastCompletion);
    }
    const result = (async () => function_(...arguments_))();
    resolve(result);
    try {
      await result;
    } catch {}
    lastCompletion = Date.now();
    next();
  };
  const enqueue = (function_, resolve, arguments_) => {
    new Promise(internalResolve => {
      queue.push(internalResolve);
    }).then(run.bind(void 0, function_, resolve, arguments_));
    (async () => {
      await Promise.resolve();
      if (!busy) {
        resumeNext();
      }
    })();
  };
  return (function_, ...arguments_) => new Promise(resolve => {
    enqueue(function_, resolve, arguments_);
  });
};
export { pLimit };
