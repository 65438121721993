import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { catchError, forkJoin, from, map, Observable, of, switchMap } from 'rxjs';
import { BrandService } from 'brand';
import { CmsService, PrismicLibService, prismicToHtmlString } from 'prismic';
import { MetaService } from 'utils';

export const articleDataResolver: ResolveFn<Observable<unknown>> = next => {
  const cms = inject(CmsService);
  const meta = inject(MetaService);
  const brand = inject(BrandService);
  const prismicLibService = inject(PrismicLibService);
  const articleId = (next.params.article as string).slice(0, 16);

  return from(cms.getById(articleId, '*')).pipe(
    switchMap(articlePrismicDocument => {
      meta.setTitle(`${brand.brand === 'yallo' ? 'yallo' : 'Lebara'} - ${articlePrismicDocument.data.title}`);
      const articleText = prismicToHtmlString(articlePrismicDocument.data.content)
        .toString()
        .replace(/<(.|\n)*?>/g, '');
      const concatArticleText = articleText.length > 160 ? articleText.slice(0, 160) + '...' : articleText;
      meta.setDescription(concatArticleText);
      return forkJoin([of(articlePrismicDocument), of(prismicLibService.getSlices(articlePrismicDocument))]);
    }),
    map(([article, slices]) => {
      return { article, slices };
    }),
    catchError(() => {
      return of(null);
    })
  );
};
