import { inject, Injectable, isDevMode } from '@angular/core';
import { PrismicDocument, RTNode } from '@prismicio/client';
import { FeatureFlagService } from 'feature-flag';
import { Promotion } from 'interfaces';
import { LanguageService } from 'language';
import { CachedDataLoaderService, StateService, StorageKeys, TransferStateService } from 'utils';
import { ChannelService } from './channel.service';
import { CmsService } from './cms.service';
import { prismicToHtmlString } from './helpers/prismic-to-html-string';
import { Channel } from './interfaces/channel.class';

export interface RawPromotion {
  id: string;
  code: string;
  lockin_lengths: { lockin_length: string }[];
  discount_amount: number;
  discount_duration: number;
  priority: number;
  title: [RTNode, ...RTNode[]];
  available_in_channels: { channel: Channel; availability: 'Default' | 'Non default' }[];
  rfe_promotion_code: string;
  zira_promotion_code: string;
}

@Injectable({
  providedIn: 'root',
})
export class PromotionService {
  private cms = inject(CmsService);
  private state = inject(StateService);
  private channels = inject(ChannelService);
  private lang = inject(LanguageService);
  private dataLoader = inject(CachedDataLoaderService);
  private transferState = inject(TransferStateService);
  private featureFlagService = inject(FeatureFlagService);
  defaultChannel = 'ol';

  private getStateKey(channel: string): StorageKeys {
    return ('promotions_' + channel + this.lang.current) as StorageKeys;
  }

  /**
   * Returns all of the Default promotions that are valid for the current language and channel
   * Default means that the promotion has been "applied": https://confluence.swi.srse.net/display/YD/Promotions
   * Will also check if there are any promotions in "promotionCodesSetAsDefault" which are not set as default.
   * This normally happens after a language change
   */
  public async getDefaultPromotions(productSpecClass: string) {
    const promotions = await this.loadDefaultPromotions(this.defaultChannel, productSpecClass);

    const oldCodes = <string[]>this.state.get(StorageKeys.PromotionCodes) || [];
    const promosToAdd = oldCodes
      .map(code => promotions.find(p => p.code === code))
      .filter(promo => !promotions.includes(promo));

    if (promosToAdd.length) {
      promosToAdd.forEach(promo => promotions.push(promo));
      this.state.set(this.getStateKey(this.defaultChannel), promotions);
    }

    return promotions;
  }

  public async getPromotionByCode(code: string, productSpecClass: string) {
    if (!code) return null;
    const isZiraEnabled = this.isZiraEnabledForClass(productSpecClass);

    const promotionCodeField = isZiraEnabled ? 'zira_promotion_code' : 'rfe_promotion_code';
    const resp = await this.cms.getByContentRelationship('promotion', promotionCodeField, code);

    return this.formatAndValidatePromotion(resp[0], productSpecClass);
  }

  private isZiraEnabledForClass(productSpecClass: string): boolean {
    const ziraEnabledProductClasses = this.featureFlagService
      .getFeatureValue('zira-enabled-product-classes', '')
      .split(',');
    return ziraEnabledProductClasses.includes(productSpecClass);
  }

  public async getPromotionById(id: string, productSpecClass: string) {
    const resp = await this.cms.getById(id);
    return this.formatAndValidatePromotion(resp, productSpecClass);
  }

  /**
   * Loads the promotions for a specific channel into the state service
   */
  private async loadPromotions(channelCode: string, productSpecClass: string) {
    return this.dataLoader.get(`promotions_${channelCode}`, async () => {
      const channel: Channel = await this.channels.findChannel(channelCode);
      const resp = await this.cms.getByContentRelationship('promotion', 'available_in_channels.channel', channel.id, {
        transferCache: false,
      });
      const availablePromotions: Promotion[] = [];
      const defaultPromotions: Promotion[] = [];

      for (const doc of resp) {
        const promo = this.formatAndValidatePromotion(doc, productSpecClass);
        if (promo) {
          availablePromotions.push(promo);
          if (promo.channels.find(_channel => channel.id === _channel.id).availability === 'Default') {
            defaultPromotions.push(promo);
          }
        }
      }
      return { availablePromotions, defaultPromotions };
    });
  }

  private formatAndValidatePromotion(doc: PrismicDocument, productSpecClass: string): Promotion {
    if (!doc) return;
    if (doc.data.environment === 'Test' && !isDevMode()) return;
    const promo = new Promotion();
    const isZiraEnabled = this.isZiraEnabledForClass(productSpecClass);
    promo.id = doc.id;
    const data = doc.data as unknown as RawPromotion;

    promo.code = isZiraEnabled ? data.zira_promotion_code : data.rfe_promotion_code;
    if (!promo.code) return null;

    promo.lockinLengths = data.lockin_lengths.map(l => parseInt(l.lockin_length)).filter((l: number) => !isNaN(l));

    promo.discountAmount = doc.data.discount_amount as number;
    promo.discountDuration = doc.data.discount_duration as number;
    promo.priority = doc.data.priority as number;

    promo.title = prismicToHtmlString(doc.data.title as [RTNode, ...RTNode[]]);
    promo.channels = (
      doc.data.available_in_channels as { channel: Channel; availability: 'Default' | 'Non default' }[]
    ).map(c => ({
      id: c.channel.id,
      availability: c.availability,
    }));

    return promo;
  }

  private loadDefaultPromotions(channelCode: string, productSpecClass: string) {
    return this.transferState.withStateTransfer(this.getStateKey(this.defaultChannel), async () => {
      const { defaultPromotions } = await this.loadPromotions(channelCode, productSpecClass);
      return defaultPromotions;
    });
  }

  /**
   * Adds the promotion with the given promo code to the list of default promotions.
   * @param promoCode
   * @returns true if promotion exist and was added to the list of default promos, false otherwise
   */
  public async setPromoAsDefault(promoCode: string, productSpecClass?: string) {
    const defaultPromotions = await this.getDefaultPromotions(productSpecClass);
    const promotion = await this.getPromotionByCode(promoCode, productSpecClass);
    if (!promotion) return false;
    if (!defaultPromotions.includes(promotion)) defaultPromotions.push(promotion);
    this.state.set(this.getStateKey(this.defaultChannel), defaultPromotions);

    const oldCodes = (this.state.get(StorageKeys.PromotionCodes) as []) || [];
    this.state.set(StorageKeys.PromotionCodes, [...oldCodes, promoCode]);
    return true;
  }
}
