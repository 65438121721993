<div class="mb-8">
  <lib-breadcrumbs [categoryChain]="categoryChain()" [mainTitle]="article()?.data.title" />

  <h1 class="font-4xl text-center mb-8">{{ article()?.data.title }}</h1>

  <ngx-dynamic-hooks
    data-source="prismic"
    [content]="articleContent()"
    [options]="{ sanitize: false }"
  ></ngx-dynamic-hooks>

  <lib-slices [slices]="slices()"></lib-slices>
</div>
