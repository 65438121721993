@mixin activeItem() {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: var(--basic-white);
  bottom: 0;
}

@mixin menuLabelActive() {
  .kitsune-menu__label {
    position: relative;

    &::after {
      @include activeItem();
      width: calc(100% - 1em);
      bottom: -0.2em;
    }
  }
}

.kitsune-nav-bar {
  background: var(--color-primary-default);

  @screen xl {
    background: none;
  }

  .title {
    color: white;
    font-weight: bold;
    font-size: larger;
    padding-left: var(--base-spacing);
    padding-right: var(--base-spacing);

    a {
      color: white;
      text-decoration: none;
    }
  }

  .dropdown {
    max-height: 0;
    max-width: 0;
    overflow: hidden;
  }

  .logo {
    fill: var(--basic-white);
  }

  .active {
    @apply h-screen overflow-hidden flex flex-col justify-between;

    .logo {
      fill: var(--color-primary);
    }
  }

  .dropdown-item {
    @apply block px-4 py-2 mt-2 text-sm font-bold bg-transparent rounded-lg md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none;
  }

  .kitsune-nav-gradient {
    @screen xl {
      background: var(--color-primary-default);
    }
  }

  .logo svg {
    width: 5rem;
    height: 3.5rem;
  }

  .logo-lebara svg {
    width: 6rem;
    height: 3.5rem;

    @screen md {
      width: 8rem;
    }
  }

  &__inner {
    border-radius: 0;

    &.active {
      padding-top: 0.25em;
    }
  }

  &--active {
    background: var(--color-primary-default);
  }

  &__menu-item {
    &:focus-visible,
    a:focus-visible {
      outline: none;
    }

    &:focus-visible {
      p {
        position: relative;

        &::after {
          @include activeItem();
        }
      }
    }

    a:focus-visible {
      position: relative;

      &::after {
        @include activeItem();
      }
    }

    &--active {
      position: relative;

      &::after {
        @include activeItem();
      }
    }
  }

  &__main {
    .kitsune-nav-menu {
      .kitsune-menu__label {
        @apply 2xl:text-xl;
      }

      .kitsune-menu {
        line-height: 1.3;

        &:hover:not(.mat-button-disabled),
        &.cdk-keyboard-focused,
        &.cdk-program-focused {
          .mat-button-focus-overlay {
            opacity: 0;
          }
        }

        &.cdk-keyboard-focused,
        &.cdk-program-focused {
          @include menuLabelActive();
        }
      }

      &--active {
        @include menuLabelActive();
      }

      &--lebara {
        .kitsune-menu {
          .kitsune-menu__label {
            @apply xl:text-xl;
            @apply font-normal;
          }
        }
      }
    }
  }
}

.kitsune-menu-mobile {
  &[aria-expanded='true'] {
    .kitsune-menu-mobile__arrow {
      transform: rotate(-180deg);
    }
  }
}
