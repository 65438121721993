%svg-spectrum {
  svg {
    overflow: visible;

    .stop1 {
      stop-color: var(--color-primary);
    }

    .stop2 {
      stop-color: var(--color-secondary);
    }
  }
}

@mixin gradient-icon($menu-items, $stroke: true, $stroke-only-on-active: false) {
  @extend %svg-spectrum;

  @each $menu-item in $menu-items {
    $processed-menu-item: str-replace($menu-item, '/', \/);
    $gradient-id: last-slash-item($menu-item);

    &:not(.active):hover .#{$processed-menu-item} svg {
      @if $stroke-only-on-active == false {
        @if $stroke {
          fill: url('#gradient-#{$gradient-id}-stroke');
        } @else {
          fill: url('#gradient-#{$gradient-id}');
        }
      }
    }

    &.active .#{$processed-menu-item} svg {
      fill: url('#gradient-#{$gradient-id}');
    }
  }
}

@mixin gradient-logo($menu-item) {
  @extend %svg-spectrum;

  &:not(.active):hover .#{$menu-item} svg {
    --posLogoSubtext: var(--color-primary);
    fill: url('#gradient-#{$menu-item}');
  }

  &.active .#{$menu-item} svg {
    --posLogoSubtext: var(--color-primary);
    fill: url('#gradient-#{$menu-item}');
  }
}

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

@function last-slash-item($string) {
  $index: _str-last-index($string, '/');

  @if $index {
    @return str-slice($string, $index + 1);
  }

  @return $string;
}

@function _str-last-index($string, $char) {
  $length: str-length($string);

  @for $i from $length through 1 {
    @if str-slice($string, $i, $i) == $char {
      @return $i;
    }
  }

  @return null;
}
