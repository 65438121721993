import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  input,
  Input,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormsModule, NgControl, ReactiveFormsModule } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { BrandService } from 'brand';
import { SvgComponent } from 'icon';
import { TranslatePipe } from 'translate';
import { AutofocusDirective } from '../autofocus.directive';
import { FormFieldBaseComponent } from '../form-field-base.component';

@Component({
  selector: 'lib-input-form-field',
  templateUrl: './input-form-field.component.html',
  styleUrls: ['./input-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    MatInputModule,
    FormsModule,
    AutofocusDirective,
    ReactiveFormsModule,
    NgxMaskDirective,
    SvgComponent,
    TranslatePipe,
  ],
  providers: [provideNgxMask()],
})
export class FormFieldComponent extends FormFieldBaseComponent {
  ngControl = inject(NgControl, { optional: true, self: true });
  protected brandService = inject(BrandService);
  @Input() autoFocus: boolean;
  @Input() loading = false;
  @Input() isBackendValid = true;
  @Input() autocomplete = '';
  @Input() theme: ThemePalette = 'primary';
  @Input() mask?: string;
  @Input() showMaskedInput = false;
  readonly maskCustomPatterns: NgxMaskDirective['patterns'] = input();
  readonly maskInputTransformFn: NgxMaskDirective['inputTransformFn'] = input((value: string | number) => {
    return value;
  });
  @Input() maxLength: number;
  @Input() showHidePassword = false;
  appearance = input<MatFormFieldAppearance>('outline');
  showPassword: boolean;
  @ViewChild('inputEl') inputEl: ElementRef;
  @ViewChild('maskedInput') maskedInput: ElementRef;
  inputOnFocus = false;

  public focus() {
    if (!this.mask) {
      return this.inputEl.nativeElement.focus();
    }
    return this.maskedInput.nativeElement.focus();
  }

  public dispatch(input: HTMLInputElement, event: string, evt?: Event) {
    input.dispatchEvent(new Event(event));
    if (event === 'input') {
      this._onInput(evt);
    }
    if (event === 'blur') {
      this.inputOnFocus = false;
      this._onBlur();
    }
    if (event === 'focus') {
      this.inputOnFocus = true;
      this._onFocus();
    }
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
    this.type = this.showPassword ? 'text' : 'password';
  }
}
