import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, inject, input, ViewEncapsulation } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { SafeHtml } from '@angular/platform-browser';
import { DynamicHooksComponent } from 'ngx-dynamic-hooks';
import { switchMap } from 'rxjs';
import { ObservableCachedDataLoaderService } from 'utils';

@Component({
  selector: 'lib-svg',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [DynamicHooksComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgComponent {
  private http = inject(HttpClient);
  private dataLoader = inject(ObservableCachedDataLoaderService);
  svg = input<string>();
  svgHtml = toSignal(
    toObservable(this.svg).pipe(
      switchMap(svg => {
        const safeSvg = svg as keyof SafeHtml;
        const svgFullPath = `/resources/${safeSvg}.svg`;
        return this.getIcon(svgFullPath);
      })
    )
  );

  getIcon(icon: string) {
    return this.dataLoader.get('icon_' + icon, () => this.http.get(icon, { responseType: 'text' }));
  }
}
