var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import { pLimit } from "./lib/pLimit.js";
import { PrismicError } from "./errors/PrismicError.js";
const UNKNOWN_RATE_LIMIT_DELAY = 1500;
class BaseClient {
  constructor(options) {
    /**
     * The function used to make network requests to the Prismic REST API. In
     * environments where a global `fetch` function does not exist, such as
     * Node.js, this function must be provided.
     */
    __publicField(this, "fetchFn");
    __publicField(this, "fetchOptions");
    /**
     * Active queued `fetch()` jobs keyed by URL and AbortSignal (if it exists).
     */
    __publicField(this, "queuedFetchJobs", {});
    /**
     * Active deduped `fetch()` jobs keyed by URL and AbortSignal (if it exists).
     */
    __publicField(this, "dedupedFetchJobs", {});
    this.fetchOptions = options.fetchOptions;
    if (typeof options.fetch === "function") {
      this.fetchFn = options.fetch;
    } else if (typeof globalThis.fetch === "function") {
      this.fetchFn = globalThis.fetch;
    } else {
      throw new PrismicError("A valid fetch implementation was not provided. In environments where fetch is not available (including Node.js), a fetch implementation must be provided via a polyfill or the `fetch` option.", void 0, void 0);
    }
    if (this.fetchFn === globalThis.fetch) {
      this.fetchFn = this.fetchFn.bind(globalThis);
    }
  }
  async fetch(url, params = {}) {
    var _a, _b, _c, _d, _e;
    const requestInit = {
      ...this.fetchOptions,
      ...params.fetchOptions,
      headers: {
        ...((_a = this.fetchOptions) == null ? void 0 : _a.headers),
        ...((_b = params.fetchOptions) == null ? void 0 : _b.headers)
      },
      signal: ((_c = params.fetchOptions) == null ? void 0 : _c.signal) || params.signal || ((_d = this.fetchOptions) == null ? void 0 : _d.signal)
    };
    if ((_e = params.fetchOptions) == null ? void 0 : _e.body) {
      return this.queueFetch(url, requestInit);
    } else {
      return this.dedupeFetch(url, requestInit);
    }
  }
  queueFetch(url, requestInit = {}) {
    const hostname = new URL(url).hostname;
    if (!this.queuedFetchJobs[hostname]) {
      this.queuedFetchJobs[hostname] = pLimit({
        interval: UNKNOWN_RATE_LIMIT_DELAY
      });
    }
    return this.queuedFetchJobs[hostname](() => this.createFetchJob(url, requestInit));
  }
  dedupeFetch(url, requestInit = {}) {
    let job;
    if (this.dedupedFetchJobs[url] && this.dedupedFetchJobs[url].has(requestInit.signal)) {
      job = this.dedupedFetchJobs[url].get(requestInit.signal);
    } else {
      this.dedupedFetchJobs[url] = this.dedupedFetchJobs[url] || /* @__PURE__ */new Map();
      job = this.createFetchJob(url, requestInit).finally(() => {
        var _a, _b;
        (_a = this.dedupedFetchJobs[url]) == null ? void 0 : _a.delete(requestInit.signal);
        if (((_b = this.dedupedFetchJobs[url]) == null ? void 0 : _b.size) === 0) {
          delete this.dedupedFetchJobs[url];
        }
      });
      this.dedupedFetchJobs[url].set(requestInit.signal, job);
    }
    return job;
  }
  createFetchJob(url, requestInit = {}) {
    return this.fetchFn(url, requestInit).then(async res => {
      let json = void 0;
      let text = void 0;
      if (res.ok) {
        try {
          json = await res.json();
        } catch {}
      } else {
        try {
          text = await res.text();
          json = JSON.parse(text);
        } catch {}
      }
      return {
        status: res.status,
        headers: res.headers,
        json,
        text
      };
    });
  }
}
export { BaseClient, UNKNOWN_RATE_LIMIT_DELAY };
