@for (slice of slices; track slice) {
  <div>
    @if (slice.label === 'bubbled') {
      <lib-curved-box>
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
      </lib-curved-box>
    }
    @if (slice.label !== 'bubbled') {
      <div>
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
      </div>
    }
    <ng-template #contentTemplate>
      <lib-lazy-slice [componentType]="slice.type" [slice]="$any(slice)"> </lib-lazy-slice>
    </ng-template>
  </div>
}
