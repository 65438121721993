import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Injector,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { LazySliceBaseService } from '../../lazy-slice-base.service';
import { AnySlice, LazyLoadedComponent, LazyLoadedComponentType } from './lasy-slice-types';

@Component({
  selector: 'lib-lazy-slice',
  templateUrl: './lazy-slice.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LazySliceComponent implements OnInit {
  private injector = inject(Injector);
  lazySliceBaseService = inject(LazySliceBaseService);
  private cdr = inject(ChangeDetectorRef);
  @Input() componentType!: string;
  @Input() slice: AnySlice;
  @ViewChild('component', { read: ViewContainerRef }) component!: ViewContainerRef;
  componentInstance!: LazyLoadedComponent;

  async ngOnInit() {
    this.componentInstance = this.createComponent(
      await this.lazySliceBaseService.loadSliceComponent(this.componentType)
    );
    this.cdr.markForCheck();
  }

  private createComponent(component: LazyLoadedComponentType) {
    this.component.clear();
    const { instance } = this.component.createComponent<LazyLoadedComponent>(component, {
      injector: this.injector,
    });
    instance.slice = this.slice;
    return instance;
  }
}
