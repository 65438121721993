import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, inject, PLATFORM_ID, signal } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { filter, startWith, switchMap } from 'rxjs';
import { AnalyticsService } from 'analytics';
import { UserService } from 'auth-data-access';
import { BrandService } from 'brand';
import { CurvedBoxComponent } from 'curved-box';
import { FooterComponent } from 'footer';
import { LanguageService } from 'language';
import { MenuGlobalComponent } from 'menu';
import { NavigationComponent } from 'navigation';
import { TranslatePipe } from 'translate';
import { appendNoScriptGoogleTagManager, appendScript, ENVIRONMENT_URLS_CONFIG_TOKEN } from 'utils';
import { environment } from '../../environments/environment';
import { SearchComponent } from '../search/search.component';

@Component({
  selector: 'app-base',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterOutlet,
    NavigationComponent,
    MenuGlobalComponent,
    FooterComponent,
    CurvedBoxComponent,
    SearchComponent,
    TranslatePipe,
  ],
  templateUrl: './base.component.html',
  styleUrl: './base.component.scss',
})
export class BaseComponent implements AfterViewInit {
  private document = inject(DOCUMENT);
  private platformId = inject(PLATFORM_ID);
  private brand = inject(BrandService);
  private analyticsService = inject(AnalyticsService);
  private userService = inject(UserService);
  private router = inject(Router);
  private config = inject(ENVIRONMENT_URLS_CONFIG_TOKEN);
  private languageService = inject(LanguageService);
  public logoUrl = signal(`${this.config.websiteUrl}/${this.languageService.current}`);

  constructor() {
    //set visitor_id for analytics if there is no visitor_id
    const visitorId = this.userService.getVisitorId();
    if (!visitorId) {
      this.userService.setVisitorId();
    }

    if (isPlatformBrowser(this.platformId)) {
      this.setupAnalytics();
    } else {
      appendNoScriptGoogleTagManager(this.document, environment.googleTagManagerId);
    }
  }

  ngAfterViewInit(): void {
    const prismicRepo = `${this.brand.brand}-support`;
    appendScript(this.document, `https://static.cdn.prismic.io/prismic.js?new=true&repo=${prismicRepo}`);
  }

  private setupAnalytics() {
    const visitorId = this.userService.getVisitorId();
    this.userService.getAccount().subscribe(account => {
      if (account) {
        const userDataAnalytics = this.analyticsService.buildUserData(account, visitorId);
        this.analyticsService.setUserData(visitorId, userDataAnalytics);
      } else {
        this.analyticsService.setUserData(visitorId);
      }
    });

    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        startWith(this.router.url), // Trigger on initial load
        switchMap(() => this.userService.getAccount())
      )
      .subscribe(account => {
        if (account) {
          const userDataAnalytics = this.analyticsService.buildUserData(account, visitorId);
          this.analyticsService.pageView({
            userDataAnalytics,
            visitorId,
            account,
            page_type: 'support',
          });
        } else {
          this.analyticsService.pageView({ visitorId, page_type: 'support' });
        }
      });
  }
}
