import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LinkField } from '@prismicio/client';
import { SvgComponent } from 'icon';
import { LinkComponent } from 'link';
import { LanguageSelectorComponent } from 'menu';
import { TranslatePipe } from 'translate';
import { FooterBottomLinksAreaSlice } from '../footer.class';

@Component({
  selector: 'lib-footer-bottom-links-area',
  templateUrl: './footer-bottom-links-area.component.html',
  imports: [LanguageSelectorComponent, LinkComponent, SvgComponent, TranslatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterBottomLinksAreaComponent {
  @Input() slice: FooterBottomLinksAreaSlice;
  goToHomeLink = <LinkField>{ link_type: 'Document', slug: '/' };
}
