import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SvgComponent } from 'icon';
import { Product } from 'product-shared';
import { TranslatePipe } from 'translate';

@Component({
  selector: 'lib-table-home-internet',
  imports: [SvgComponent, TranslatePipe],
  templateUrl: './table-home-internet.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableHomeInternetComponent {
  @Input() product: Product;
}
