import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { SelfcareService } from '@yol-digital/ms-client';
import { CookieService } from 'ngx-cookie-service';
import { catchError, map, Observable, of, switchMap, tap } from 'rxjs';
import { FeatureFlagService } from 'feature-flag';
import { ProductService } from 'product-shared';
import { ToastService } from 'toast';
import { TranslateService } from 'translate';
import {
  ENVIRONMENT_URLS_CONFIG_TOKEN,
  EnvironmentUrlsConfig,
  ObservableCachedDataLoaderService,
  randomString,
  StorageKeys,
  StorageService,
} from 'utils';
import { AuthService } from './auth.service';
import Api = SelfcareService.Api;

type AccountResp = SelfcareService.AccountResp;
type SubscriptionsResp = SelfcareService.SubscriptionsResp;
type SubscriptionResp = SelfcareService.SubscriptionResp;

interface Data {
  [key: string]: number;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private storageService = inject(StorageService);
  private http = inject(HttpClient);
  private authService = inject(AuthService);
  private cookieService = inject(CookieService);
  private productsService = inject(ProductService);
  private cachedDataLoader = inject(ObservableCachedDataLoaderService);
  private translateService = inject(TranslateService);
  private toasts = inject(ToastService);
  private featureFlagService = inject(FeatureFlagService);
  private config = inject<EnvironmentUrlsConfig>(ENVIRONMENT_URLS_CONFIG_TOKEN);
  private platformId = inject(PLATFORM_ID);
  private api: Api;

  constructor() {
    this.api = new Api(this.config.newMicroServiceEndpoint, this.http);
  }

  setVisitorId() {
    const visitorId = randomString(24);
    this.storageService.set(StorageKeys.VisitorId, visitorId);
  }

  getVisitorId() {
    return this.storageService.get<string>(StorageKeys.VisitorId);
  }

  getAccount(): Observable<AccountResp | null> {
    return this.isAuthenticated().pipe(
      switchMap(isAuthenticated => {
        if (!isAuthenticated) return of(null);
        return this.cachedDataLoader.get('account', () => {
          return this.api.public.account().pipe(
            map(account => {
              if ('error' in account) throw new Error(account.error);
              return account;
            }),
            catchError(err => {
              if (!err?.wasCaught) {
                console.error(err);
                this.toasts.add(this.translateService.getTranslation(['server_error']), false);
              }
              return of(null);
            })
          );
        });
      })
    );
  }

  public setCustomerAddress(key: StorageKeys) {
    return this.getAccount().pipe(
      tap(account => {
        const billingAddress = account?.billingAddress;
        if (billingAddress) {
          this.storageService.set(key, {
            postCode: billingAddress.zip,
            city: billingAddress.city,
            streetName: billingAddress.street,
            streetNumber: billingAddress.streetNumber,
            ...(billingAddress.co ? { co: billingAddress.co } : {}),
          });
        }
      })
    );
  }

  public getSubscriptions(): Observable<SubscriptionsResp[] | null> {
    return this.isAuthenticated().pipe(
      switchMap(isAuthenticated => {
        if (!isAuthenticated) return of(null);
        return this.cachedDataLoader.get('subscriptions', () =>
          this.api.public.subscriptions().pipe(
            map(subscriptions => {
              if ('error' in subscriptions) throw new Error(subscriptions.error);
              return subscriptions.filter(sub => sub.subscriptionStatus !== 'terminated');
            }),
            catchError(err => {
              if (!err?.wasCaught) {
                console.error(err);
                this.toasts.add(this.translateService.getTranslation(['server_error']), false);
              }
              return of(null);
            }),
            tap(subscriptions => subscriptions && this.setPreviouslyLoggedInCookie(subscriptions))
          )
        );
      })
    );
  }

  public getSubscriptionById(id: string): Observable<SubscriptionResp | null> {
    return this.isAuthenticated().pipe(
      switchMap(isAuthenticated => {
        if (!isAuthenticated) return of(null);
        return this.cachedDataLoader.get(`subscription_${id}`, () =>
          this.api.public.subscription(id).pipe(
            map(subscription => {
              if ('error' in subscription) throw new Error(subscription.error);
              return subscription;
            })
          )
        );
      })
    );
  }

  updateContactDetails(data: { contactNumber: string }) {
    return this.isAuthenticated().pipe(
      switchMap(isAuthenticated => {
        if (!isAuthenticated) return of(null);
        return this.api.public.updateContactDetails(data).pipe(
          tap(() => {
            this.cachedDataLoader.remove('account');
          })
        );
      })
    );
  }

  updateUserEmail(email?: string) {
    return this.isAuthenticated().pipe(
      switchMap(isAuthenticated => {
        if (!isAuthenticated) return of(null);
        return this.api.public.updateEmail({ newEmail: email });
      })
    );
  }

  updateUserBillingAddress(data: SelfcareService.UpdateBillingAddressReq) {
    return this.isAuthenticated().pipe(
      switchMap(isAuthenticated => {
        if (!isAuthenticated) return of(null);
        return this.api.public.updateBillingAddress(data).pipe(
          tap(() => {
            this.cachedDataLoader.remove('account');
          })
        );
      })
    );
  }

  /**
   * Sets a the "previouslyLoggedInState" cookie which is used for detecting if a customer was logged in, and what kind of subscriptions they had
   */
  private async setPreviouslyLoggedInCookie(subscriptions: SubscriptionsResp[]) {
    const activeSubs = subscriptions.filter(({ subscriptionStatus }) => subscriptionStatus === 'active');
    const rateplans = await this.productsService.getPCProducts();
    const prepaidCount = activeSubs.filter(({ productCode }) => {
      const product = rateplans.find(({ code }) => code === productCode.toUpperCase());
      return product?.productSpecCategory === 'PREPAID';
    }).length;
    const postpaidCount = activeSubs.filter(({ productCode }) => {
      const product = rateplans.find(({ code }) => code === productCode.toUpperCase());
      return product?.productSpecCategory === 'POSTPAID';
    }).length;
    const mobilePostpaidCount = activeSubs.filter(({ productCode }) => {
      const product = rateplans.find(({ code }) => code === productCode.toUpperCase());
      return product?.productSpecCategory === 'POSTPAID' && product.productSpecFamily === 'MOBILE';
    }).length;
    const string = `prepaid=${prepaidCount}&postpaid=${postpaidCount}&mobilePostpaid=${mobilePostpaidCount}`;
    this.cookieService.set('previouslyLoggedInState', string, 90);
  }

  // Gets the "previouslyLoggedInState" cookie and converts it into an object
  public getPreviouslyLoggedInData(): { prepaid: number; postpaid: number; mobilePostpaid: number } | undefined {
    const val = this.cookieService.get('previouslyLoggedInState');
    if (!val) return;
    const vals = val.split('&');
    const data: Data = {};
    vals.forEach(v => (data[v.split('=')[0]] = parseInt(v.split('=')[1])));
    return <{ prepaid: number; postpaid: number; mobilePostpaid: number }>data;
  }

  public isAuthenticated(): Observable<boolean> {
    if (!isPlatformBrowser(this.platformId)) return of(false); // Don't check login token on the server
    return this.authService.getToken().pipe(
      map(Boolean),
      tap(loggedIn => {
        this.featureFlagService.setAttributeOverrides({ loggedIn });
      })
    );
  }
}
